@if (step === 0) {
  <div mat-dialog-content>
    <ngx-extended-pdf-viewer [src]="pdf" height="100%"></ngx-extended-pdf-viewer>
  </div>
}

<div class="text-center">
    <div mat-dialog-content [hidden]="step !== 1">
      <h5>Signature du responsable SDLV</h5>
      <canvas #canvasRI class="border"></canvas>
    </div>

    <div mat-dialog-content [hidden]="step < 2">
      <h5>Signature du responsable légal</h5>
      <canvas #canvasRL class="border"></canvas>
    </div>

  <div *ngIf="isSaveError === true" class="alert alert-danger" role="alert">
    Une erreur est survenue !
  </div>
</div>

<div class="d-flex flex-row gap-10 justify-content-center" mat-dialog-actions>

  <button class="btn btn-danger" (click)="onBtnCancelClick()">Annuler</button>
  <button *ngIf="step >= 1" class="btn btn-warning" (click)="onBtnClearClick()">Nettoyer</button>
  <button class="btn btn-primary" (click)="onBtnNextClick()"
          [disabled]="step === 1 && !isRISigned || step === 2 && !isRLSigned">
    <span class="spinner-border spinner-border-sm" *ngIf="isSaveBtnLoading"></span>
    {{ step < 2 ? "Suivant" : "Terminer" }}
  </button>
</div>
